.balanceBox{
  background: #1a1f2e;
  border-radius: 10px;
  color: #FFF;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 30px;
  span{
    font-size: 15px;
    margin-left: .5rem
  }
  p{
    margin-bottom: 0;
  }

  &.small{
    @media (max-width: 1024px){
      font-size: 15px;
    }
    span{
      @media (max-width: 1024px){
        font-size: 10px;
      }
    }
  }


}

.closeMessage{
  position: absolute;
  top: .5rem;
  right: 1rem;
  color: #FFF;
  cursor: pointer;
}

.pageContainer{
  @media (max-width: 1024px) {
      height: auto !important;
      display: block;
  }
}
