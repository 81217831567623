@import "custom";

// .backgroundImage {
//   resize-mode: 'cover';
//   justify-content: 'center';
//   width:'100%';
//   height:'100%';
//   background-size: cover;
//   position: 'absolute';
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }

.App {
  text-align: center;
  background: #000;
  background-size: cover;
  background:  url("./assets/images/bg_small.png") #000 center center;
  flex: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loadingPopup, #walletPopup{
  width: 90%;
  max-width: 680px;
  min-height: 200px;
  position: fixed;
  background: rgba(255, 104, 42, .9);
  left: 0;
  right: 0;
  height: auto;
  margin: 0 auto;
  border-radius: 20px;
  z-index: 11;
  color: #FFF;
  display: none;
  font-weight: bolder;
  &.show{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

#walletPopup{
  display: flex;
  top: 20%;
  align-items: center;
  justify-content: space-evenly;

  span{
    position: absolute;
    top: 5px;
    right: 15px;
  }

  div{
    width: 50%;
    height: 100%;

    &:hover, &:active{
      background-color: rgba(0,0,0,0.2);
    }

  }
}

#loadingBack{
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.brandBtn{
  background-color: #ff682a;
  border-color: #ff682a;
  color: #FFF;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: .5rem 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
  box-shadow: 0 0 44px -1px #ff682a80;

  &.full{
    width: 360px;
  }

  &.attached{
    border-radius: 0 100px 100px 0;
  }
  &:disabled{
    background-color: #ff682a !important;
    border-color: #ff682a !important;
    opacity: 0.5;
  }

  &:hover{
    background-color: transparent !important;
    color: #ff682a !important;
    border-color: #ff682a;
  }
}

.maxBtn{
  position: absolute;
  left: 150px;
  top: 7px;
  background-color: transparent !important;
  color: #ff682a !important;
  border-color: #ff682a !important;
  font-size: 10px;
  &:hover{
    background-color: #ff682a !important;
    color: #FFF !important;
  }
}

.stakeInputWrapper{
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  > div{
    display: flex;
    flex-direction: row;
    margin: 1rem;
  }
}
//@media (max-width: 576px) {
//  .stakeInputWrapper {
//    display: grid;
//    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
//    position: relative;
//  }
//}
.brandInput{
  border-color: #ff682a;
  border-radius: 100px 0 0 100px;
  background-color: transparent !important;
  color: #FFF !important;
  outline: none;
  width: 120px;

  text-align: center;
  font-size: 25px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  @media (max-width: 1024px) {
    width: 150px;
  }
}

.form-check-input:checked{
  background-color: #ff682a;
  border-color: #ff682a;
}
